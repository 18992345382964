import React from 'react';

import CompareTool from '../components/compare-tool';
import PageLayout from '../components/page-layout';
import SEO from '../components/seo';
import {
  ComparePageWrapper,
  ContentContainer
} from '../components/content-layout';

import { Router } from '@reach/router';

const ComparePage = () => (
  <PageLayout layout={`full`}>
    <SEO title="Compare Articles" />
    <ComparePageWrapper>
      <ContentContainer layout={`full`}>
        <Router>
          <CompareTool path="/compare/:articleIdString" />
        </Router>
      </ContentContainer>
    </ComparePageWrapper>
  </PageLayout>
);

export default ComparePage;
