import { jsonapiBaseUrl } from './utils';

// File to hold helper functions related to the compare tool.

/**
 * Returns the url used to query JSONAPI for comparing a set of case studies.
 *
 * @param {Array} ids
 *   A list of Drupal node ids of case studies.
 */
export const getCaseStudiesCompareUrl = (ids) => {
  const include = [
    'field_key_design_decisions',
    'field_key_design_decisions.field_key_design_decision_term',
    'field_custom_content',
    'field_countries_and_regions',
    'field_crises',
    'field_institutions',
    'field_intervention_type',
    'field_issues',
    'field_key_terms',
    'field_key_terms.field_key_terms_term',
    'field_resources'
  ];

  const idFilters = [
    'filter[id-filter][condition][path]=id',
    'filter[id-filter][condition][operator]=IN'
  ];

  ids.forEach((id) =>
    idFilters.push(`filter[id-filter][condition][value][]=${id}`)
  );

  const url = `${jsonapiBaseUrl}/node/case_studies?include=${include.join(
    ','
  )}&${idFilters.join('&')}`;

  return url;
};

export const getCaseStudiesForCompare = async ({ articleIds }) => {
  const response = await fetch(getCaseStudiesCompareUrl(articleIds));
  return response.json();
};

/**
 * Determine the compare mode to use based on a set of articles.
 *
 * @param {object} articlesJsonapi
 *   JSONAPI representation of a set of articles.
 */
export const getCompareMode = (articlesJsonapi) => {
  // "Full" compare means we show all rows even if some items do not have a value.
  // "Partial" compare means we only show rows where all records have a value. We
  // check whether the set of articles share all intervention types in common. If they do,
  // we use the full compare mode. If not, we use partial.
  return articlesJsonapi.data
    .reduce((interventionIds, article) => {
      const ids = article.relationships.field_intervention_type.data.map(
        (item) => item.id
      );
      return interventionIds.concat(ids);
    }, [])
    .reduce((mode, interventionId) => {
      // Switch to partial if any article lacks one of the common intervention types.
      articlesJsonapi.data.forEach((article) => {
        if (
          !article.relationships.field_intervention_type.data.find(
            (item) => item.id === interventionId
          )
        ) {
          mode = 'partial';
        }
      });

      return mode;
    }, 'full');
};

const compareNormalizationMap = {
  field_key_design_decisions: {
    valueField: 'field_short_summary',
    valueFieldSecondary: 'field_title',
    keyField: 'field_key_design_decision_term'
  },
  field_key_terms: {
    valueField: 'field_key_terms_body',
    keyField: 'field_key_terms_term'
  }
};

const allowedMetadataFields = [
  'field_crises',
  'field_issues',
  'field_countries_and_regions',
  'field_intervention_type'
];

/**
 * Transform JSONAPI representation of case study.
 *
 * @param {*} caseStudy
 */
export const normalizeArticleFields = (articlesJsonapi) => {
  const getInclude = (id) =>
    articlesJsonapi.included.find((include) => include.id === id);

  return articlesJsonapi.data.map((article) => {
    // Each attribute goes in a separate grouping.

    const attributeGroups = Object.keys(article.attributes).map(
      // We dump all of the data from simple attributes into the group "data" prop.
      (attributeName) => {
        if (!attributeName.startsWith('field_')) {
          return null;
        }
        const data = article.attributes[attributeName];
        return {
          id: attributeName,
          data
        };
      }
    );

    // Build the sticky title heading.
    const titleHeadingGroup = {
      id: 'titleHeading',
      data: {
        title: article.attributes.title,
        path: article.attributes.path
      }
    };

    // Relationship fields normalize by putting their children in a property.
    const relationshipGroups = Object.keys(article.relationships)
      .filter(
        (key) =>
          (compareNormalizationMap[key] ||
            allowedMetadataFields.includes(key)) &&
          article.relationships[key].data
      )
      .map((relationshipName) => {
        const _data = article.relationships[relationshipName].data;
        if (!_data) {
          return null;
        }
        // Correct for different structure between single and multivalue.
        const relationshipData = _data.length ? _data : [_data];
        if (!relationshipData[0].type) {
          return null;
        }
        const entityType = relationshipData[0].type.split('--')[0];

        // This logic is for key terms and kdds mostly.
        if (entityType === 'paragraph') {
          const children = relationshipData.map((childItem) => {
            const includedEntity = getInclude(childItem.id);

            const {
              keyField,
              valueField,
              valueFieldSecondary
            } = compareNormalizationMap[relationshipName];

            const termEntity = getInclude(
              includedEntity.relationships[keyField].data.id
            );
            let value = includedEntity.attributes[valueField];
            if (
              !value &&
              valueFieldSecondary &&
              includedEntity.attributes[valueFieldSecondary]
            ) {
              value = includedEntity.attributes[valueFieldSecondary];
            }

            return {
              key: termEntity.attributes.name,
              value: value,
              effectiveness:
                includedEntity.attributes.field_effectiveness || undefined
            };
          });

          return {
            id: relationshipName,
            children
          };
        }
        if (entityType === 'taxonomy_term') {
          const data = relationshipData
            .reduce((termValues, childItem) => {
              const termEntity = getInclude(childItem.id);
              return termValues.concat([termEntity.attributes.name]);
            }, [])
            .join(', ');

          return {
            id: relationshipName,
            data
          };
        }
        return null;
      });

    const keyTermsData = relationshipGroups.find(
      (group) => group?.id === 'field_key_terms'
    );

    // Build the dateline group.
    const dateLineGroup = {
      id: 'dateline',
      data: keyTermsData
        ? keyTermsData.children.reduce((dateline, currentKeyTerm) => {
            // Reduce key terms until we have derived a dateline.
            if (dateline) {
              return dateline;
            }
            if (currentKeyTerm.key.match(/announce/i)) {
              return `Announced ${currentKeyTerm.value}`;
            }
            if (currentKeyTerm.key.match(/operational/i)) {
              return `Operational ${currentKeyTerm.value}`;
            }
            return false;
          }, false)
        : null
    };

    return {
      groups: attributeGroups
        .concat(relationshipGroups)
        .concat([dateLineGroup])
        .concat([titleHeadingGroup])
        .filter((group) => group)
    };
  });
};

export const buildCompareMatrixRows = (articles, mode) => {
  const allArticleIndexes = articles.map((article, index) => index);

  // First, combine article values into a rows object.
  const reducedArticles = articles.reduce(
    (rowGroups, article, articleIndex) => {
      article.groups.forEach((articleGroup) => {
        // Initialize a row grouping.
        const articleGroupId = articleGroup.id;
        if (!rowGroups[articleGroupId]) {
          rowGroups[articleGroupId] = {
            id: articleGroupId,
            rows: {}
          };
        }

        // Some sections have children, whereas some are singular and rely on a data property.
        if (articleGroup.children) {
          articleGroup.children.forEach((keyValue) => {
            if (!rowGroups[articleGroupId].rows[keyValue.key]) {
              rowGroups[articleGroupId].rows[keyValue.key] = {};
            }

            // We store cell values as an array because of situations where an article has
            // multiple records (e.g. kdds) that reference the same term. In this case, we
            // create a list from the list of values per term.
            if (!rowGroups[articleGroupId].rows[keyValue.key][articleIndex]) {
              rowGroups[articleGroupId].rows[keyValue.key][articleIndex] = [];
            }
            rowGroups[articleGroupId].rows[keyValue.key][articleIndex].push(
              keyValue
            );
          });
        } else if (articleGroup.data) {
          if (!rowGroups[articleGroupId].rows[articleGroupId]) {
            rowGroups[articleGroupId].rows[articleGroupId] = {};
          }
          rowGroups[articleGroupId].rows[articleGroupId][articleIndex] =
            articleGroup.data;
        }
      });

      return rowGroups;
    },
    {}
  );

  // Pad cells and do a final transform.
  const paddedRowGroups = Object.values(reducedArticles).map((rowGroup) => {
    const rows = Object.keys(rowGroup.rows).map((rowKey) => {
      const rowValues = rowGroup.rows[rowKey];
      allArticleIndexes.forEach((singleArticleIndex) => {
        if (!rowValues[singleArticleIndex]) {
          rowValues[singleArticleIndex] = false;
        }
      });

      const values = Object.values(rowValues);

      return {
        values,
        key: rowKey
      };
    });

    return {
      ...rowGroup,
      single: rows.length === 1,
      rows
    };
  });

  if (mode === 'full') {
    return paddedRowGroups;
  }

  // If we are doing a partial compare, filter out rows that have any empty values.
  return paddedRowGroups.map((rowGroup) => {
    const rows = rowGroup.rows.filter((row) => {
      return row.values.filter((value) => value).length === row.values.length;
    });

    return {
      ...rowGroup,
      rows
    };
  });
};
