import React, { useState } from 'react';
import reactHtmlParser from 'react-html-parser';
import classNames from 'classnames';
import Arrow from '../article-section-collapsible/assets/icon-chevron--blue.svg';

import styles from './compare-matrix.module.scss';

const CellDefault = ({ value }) => {
  const EmptyValue = ({ children }) => (
    <span className={styles.emptyText}>{children}</span>
  );

  const cellValue = value ? value : <EmptyValue>N/A</EmptyValue>;

  const CellValueInner = ({ innerValue }) =>
    innerValue?.processed ? reactHtmlParser(innerValue.processed) : innerValue;

  // In some cases value is an array. This is when an article has multiple records sharing a common
  // KDD.
  if (Array.isArray(value)) {
    return (
      <>
        {value.length > 1 && (
          <ul className={styles.contentList}>
            {value.map((singleValue, index) => (
              <li key={index}>
                {singleValue.effectiveness &&
                (singleValue.effectiveness === 'interesting' ||
                  singleValue.effectiveness === 'caution') ? (
                  <>
                    <span className={styles.effectivenessText}>
                      Effectiveness rating: {singleValue.effectiveness}
                    </span>
                    <span
                      className={classNames(styles.effectivenessWrapper, {
                        [styles.interesting]:
                          singleValue.effectiveness === 'interesting',
                        [styles.caution]:
                          singleValue.effectiveness === 'caution'
                      })}
                    >
                      <CellValueInner innerValue={singleValue.value} />
                    </span>
                  </>
                ) : (
                  <CellValueInner innerValue={singleValue.value} />
                )}
              </li>
            ))}
          </ul>
        )}
        {value.length === 1 && (
          <>
            {value[0].effectiveness &&
            (value[0].effectiveness === 'interesting' ||
              value[0].effectiveness === 'caution') ? (
              <>
                <span className={styles.effectivenessText}>
                  Effectiveness rating: {value[0].effectiveness}
                </span>
                <span
                  className={classNames(styles.effectivenessWrapper, {
                    [styles.interesting]:
                      value[0].effectiveness === 'interesting',
                    [styles.caution]: value[0].effectiveness === 'caution'
                  })}
                >
                  <CellValueInner innerValue={value[0].value} />
                </span>
              </>
            ) : (
              <CellValueInner innerValue={value[0].value} />
            )}
          </>
        )}
      </>
    );
  }

  return <CellValueInner innerValue={cellValue} />;
};

export const CompareMatrixRow = ({
  label,
  labelHidden = false,
  labelScope = 'row',
  headerCells = false,
  collapsibleRow = false,
  parentStatus,
  values,
  ResolveCell
}) => {
  const [expandedRow, setExpandedRow] = useState(true);

  // This component can handle either basic text or a text.processed as it
  // may come over from Drupal.
  const Cell = ResolveCell ? ResolveCell : CellDefault;

  const rowClasses = {
    [styles.row]: true,
    [styles.headerRow]: headerCells === true
  };

  const labelClasses = {
    [styles.labelSmall]: true,
    [styles.labelHidden]: labelHidden === true
  };

  return (
    <tr className={classNames(rowClasses)} data-parent-status={parentStatus}>
      <th
        className={styles.labelCell}
        scope={labelScope}
        data-collapsible={collapsibleRow}
        data-expanded={expandedRow}
      >
        {headerCells ? (
          <h2 className={styles.label}>{label}</h2>
        ) : (
          <h4 className={classNames(labelClasses)}>
            {collapsibleRow ? (
              <button
                className={styles.rowToggle}
                onClick={() =>
                  setExpandedRow(expandedRow === true ? false : true)
                }
              >
                {label}
                <Arrow />
              </button>
            ) : (
              <>{label}</>
            )}
          </h4>
        )}
      </th>
      {values.map((value, index) => (
        <>
          {headerCells ? (
            <th className={styles.dataCell} key={index} scope={'col'}>
              <Cell value={value} />
            </th>
          ) : (
            <td className={styles.dataCell} key={index}>
              <Cell value={value} />
            </td>
          )}
        </>
      ))}
    </tr>
  );
};

export const CompareMatrixRowSingle = ({
  label,
  labelHidden,
  labelScope,
  headerCells,
  collapsibleRow,
  parentStatus,
  id,
  matrixGroups,
  ResolveCell
}) => {
  const matrixGroup = matrixGroups.find((group) => group.id === id);
  if (!matrixGroup.rows.length > 0) {
    return null;
  }
  const values = matrixGroup.rows[0].values;
  return (
    <CompareMatrixRow
      label={label}
      labelHidden={labelHidden}
      labelScope={labelScope}
      headerCells={headerCells}
      collapsibleRow={collapsibleRow}
      parentStatus={parentStatus}
      values={values}
      ResolveCell={ResolveCell}
    />
  );
};

export const CompareMatrixGroup = ({ label, labelSpan, id, matrixGroups }) => {
  const [groupRow, setGroupRow] = useState(true);
  const group = matrixGroups.find((group) => group.id === id);

  if (!group) {
    return null;
  }
  const rows = group.rows;
  if (rows.length > 0) {
    return (
      <CompareMatrixGroupWrapper>
        <tr
          className={styles.groupRow}
          data-expanded={groupRow}
          data-group={''}
        >
          <th className={styles.labelCell} colSpan={labelSpan} scope={'col'}>
            <h3 className={styles.label}>
              <button
                className={styles.groupRowToggle}
                onClick={() => setGroupRow(groupRow === true ? false : true)}
              >
                {label}
                <Arrow />
              </button>
            </h3>
          </th>
        </tr>
        {rows.map((row) => (
          <CompareMatrixRow
            label={row.key}
            values={row.values}
            key={row.key}
            parentStatus={groupRow}
          />
        ))}
      </CompareMatrixGroupWrapper>
    );
  } else {
    return <CompareMatrixGroupWrapper />;
  }
};

export const CompareMatrixGroupMerged = ({
  label,
  labelSpan,
  ids,
  matrixGroups
}) => {
  const [mergedGroupRow, setMergedGroupRow] = useState(true);
  // Derive a rows list from matrix groups.
  const mergedChildRows = Object.keys(ids).reduce((aggValues, id) => {
    const group = matrixGroups.find((group) => group.id === id);
    return group ? aggValues.concat(group.rows) : aggValues;
  }, []);

  return (
    <CompareMatrixGroupWrapper>
      <tr
        className={styles.groupRow}
        data-expanded={mergedGroupRow}
        data-merged-group={''}
      >
        <th className={styles.labelCell} colSpan={labelSpan} scope={'col'}>
          <button
            className={styles.groupRowToggle}
            onClick={() =>
              setMergedGroupRow(mergedGroupRow === true ? false : true)
            }
          >
            {label}
            <Arrow />
          </button>
        </th>
      </tr>
      {mergedChildRows.map((row, index) => (
        <CompareMatrixRow
          label={ids[row.key]}
          parentStatus={mergedGroupRow}
          values={row.values}
          key={index}
        />
      ))}
    </CompareMatrixGroupWrapper>
  );
};

const CompareMatrixGroupWrapper = ({ children }) => <>{children}</>;
