import React from 'react';
import styles from './loading-indicator.module.scss';

const LoadingIndicator = () => (
  <div className={styles.indicator}>
    <span>Loading&hellip;</span>
  </div>
);

export default LoadingIndicator;
