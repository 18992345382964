import React from 'react';
import { useAsync } from 'react-async';
import {
  getCaseStudiesForCompare,
  normalizeArticleFields,
  buildCompareMatrixRows,
  getCompareMode
} from '../../utils.compare';

import {
  CompareMatrixGroup,
  CompareMatrixRowSingle,
  CompareMatrixGroupMerged
} from '../compare-matrix';

import LoadingIndicator from '../loading-indicator';
import Error from '../error';

import styles from './compare-tool.module.scss';
import { Link } from 'gatsby';

const CompareTool = ({ articleIdString }) => {
  const { data, error, isPending } = useAsync({
    promiseFn: getCaseStudiesForCompare,
    articleIds: articleIdString.split(',')
  });

  const articles = data ? normalizeArticleFields(data) : null;
  const compareMode = data ? getCompareMode(data) : null;
  const matrixGroups = articles
    ? buildCompareMatrixRows(articles, compareMode)
    : null;
  const ArticleHeading = ({ value }) => (
    <strong>
      <Link className={styles.articleHeading} to={value.path.alias}>
        {value.title}
      </Link>
    </strong>
  );
  const articleCount = articles?.length;

  return (
    <>
      <h1 className={styles.heading}>Article comparison table</h1>
      {matrixGroups && (
        <table className={styles.compareContainer}>
          <tbody data-count={articleCount}>
            <CompareMatrixRowSingle
              id="titleHeading"
              label="Compare Articles:"
              labelScope="col"
              headerCells={true}
              matrixGroups={matrixGroups}
              ResolveCell={ArticleHeading}
            />
            <CompareMatrixRowSingle
              label="Announced/operational"
              id="dateline"
              matrixGroups={matrixGroups}
            />
            <CompareMatrixRowSingle
              label="Intervention Type"
              id="field_intervention_type"
              matrixGroups={matrixGroups}
            />
            <CompareMatrixRowSingle
              label="Purpose"
              id="field_purpose"
              collapsibleRow={true}
              matrixGroups={matrixGroups}
            />
            <CompareMatrixRowSingle
              label="Summary Evaluation"
              id="field_summary_evaluation"
              collapsibleRow={true}
              matrixGroups={matrixGroups}
            />
            <CompareMatrixGroup
              label="Key Design Decisions"
              labelSpan={articleCount + 1}
              id="field_key_design_decisions"
              matrixGroups={matrixGroups}
            />
            <CompareMatrixGroup
              label="Key Terms"
              labelSpan={articleCount + 1}
              id="field_key_terms"
              matrixGroups={matrixGroups}
            />
            <CompareMatrixGroupMerged
              label="Metadata"
              labelSpan={articleCount + 1}
              ids={{
                field_crises: 'Crises',
                field_issues: 'Issues',
                field_countries_and_regions: 'Countries and Regions'
              }}
              matrixGroups={matrixGroups}
            />
            <tr className={styles.keyRow} aria-hidden={'true'}>
              <th scope={'row'}>Effectiveness Ratings:</th>
              <td colSpan={articleCount}>
                <span className={styles.keyOption}>
                  <span className={styles.interesting}>Interesting</span>
                </span>
                <span className={styles.keyOption}>
                  <span className={styles.caution}>Caution</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      )}
      {error && (
        <Error>Sorry, there was an error loading articles for compare.</Error>
      )}
      {isPending && <LoadingIndicator />}
    </>
  );
};

export default CompareTool;
